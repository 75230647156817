<template>
  <div class="direct-flight-select mb-1 justify-content-between ml-1">
    <b-form-checkbox
      v-model="isSelected"
      :disabled="isDisabled"
      switch
      class="custom-control-warning"
      @input="val => handleUpdate(val)"
    >
      <span :class="`text-nowrap text-body font-weight-bold ${isDisabled ? 'text-muted' : ''}`">{{ $t('flight.isGroupedItineraryResponse') }}</span>
    </b-form-checkbox>
  </div>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue'
import { ref, watch, computed } from '@vue/composition-api'

export default {
  components: {
    BFormCheckbox,
  },
  props: {
    isSearchMonthlyCheapestFare: {
      type: Boolean,
      required: true,
    },
    isSearchClassBooking: {
      type: Boolean,
      required: true,
    },
    isGroupedItineraryResponse: {
      type: [Boolean, undefined],
      default: false,
      required: true,
    },
    isDisabledGroupedItinerary: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isSelected = ref(false)

    watch(() => props.isGroupedItineraryResponse, val => {
      if (val === true || val === false) {
        isSelected.value = val
      }
    }, { immediate: true })

    watch(() => [props.isSearchClassBooking, props.isSearchMonthlyCheapestFare], ([classBookingVal, monthlyCheapestFareVal]) => {
      if (classBookingVal || monthlyCheapestFareVal) {
        isSelected.value = false
      }
    }, { immediate: true })

    function handleUpdate(val) {
      emit('update:isGroupedItineraryResponse', val)
    }

    const isDisabled = computed(() => props.isSearchMonthlyCheapestFare || props.isSearchClassBooking || props.isDisabledGroupedItinerary)

    return {
      isSelected,
      handleUpdate,
      isDisabled,
    }
  },
}
</script>

<style lang="scss" scoped>
.direct-flight-select {
  margin-bottom: -7px !important;
}
</style>
